import "../assets/css/nucleo-icons.css";
import "../assets/css/nucleo-svg.css";
import "../assets/css/soft-ui-dashboard.css";

import ImportJavascript from "./importJavascript";

const Footer = () => {
  <ImportJavascript />;

  return (
    <footer className="footer pt-3 mt-4">
      <div className="container-fluid">
        <div className="row align-items-center justify-content-lg-between">
          <div className="col-lg-6 mb-lg-0 mb-4">
            <div className="copyright text-center text-sm text-muted text-lg-start">
              &copy; Copyright {new Date().getFullYear()}{" "}
              <strong>Trivut Corp.</strong>
            </div>
          </div>
          <div className="col-lg-6">
            <ul className="nav nav-footer justify-content-center justify-content-lg-end">
              <li className="nav-item">
                <a
                  href="https://www.trivut.com"
                  className="nav-link text-muted"
                  target="_blank"
                  rel="noreferrer"
                >
                  About
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="mailto:sales@trivut.com"
                  className="nav-link text-muted"
                  target="_blank"
                  rel="noreferrer"
                >
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
