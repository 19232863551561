export const LinkedInData = [
  {
    id: 1,
    year: "Sept '22",
    followersGain: 0,
    totalFollowers: 0,
  },
  {
    id: 2,
    year: "Oct '22",
    followersGain: 20,
    totalFollowers: 20,
  },
  {
    id: 3,
    year: "Nov '22",
    followersGain: 7,
    totalFollowers: 27,
  },
  {
    id: 4,
    year: "Dec '22",
    followersGain: 20,
    totalFollowers: 47,
  },
  {
    id: 5,
    year: "Jan '23",
    followersGain: 14,
    totalFollowers: 61,
  },
  {
    id: 6,
    year: "Feb '23",
    followersGain: 8,
    totalFollowers: 69,
  },
  {
    id: 7,
    year: "Mar '23",
    followersGain: 28,
    totalFollowers: 97,
  },
  {
    id: 8,
    year: "Apr '23",
    followersGain: 25,
    totalFollowers: 122,
  },
  {
    id: 9,
    year: "May '23",
    followersGain: 23,
    totalFollowers: 145,
  },
  {
    id: 10,
    year: "Jun '23",
    followersGain: 39,
    totalFollowers: 184,
  },
  {
    id: 11,
    year: "Jul '23",
    followersGain: 10,
    totalFollowers: 194,
  },
  {
    id: 12,
    year: "Aug '23",
    followersGain: 20,
    totalFollowers: 214,
  },
  {
    id: 13,
    year: "Sept '23",
    followersGain: 18,
    totalFollowers: 232,
  },
  {
    id: 14,
    year: "Oct '23",
    followersGain: 18,
    totalFollowers: 250,
  },
  {
    id: 15,
    year: "Nov '23",
    followersGain: 5,
    totalFollowers: 255,
  },
  {
    id: 16,
    year: "Dec '23",
    followersGain: 0,
    totalFollowers: 255,
  },
  {
    id: 17,
    year: "Jan '24",
    followersGain: 1,
    totalFollowers: 254,
  },
  {
    id: 18,
    year: "Feb '24",
    followersGain: 3,
    totalFollowers: 257,
  },
  {
    id: 19,
    year: "Mar '24",
    followersGain: 0,
    totalFollowers: 257,
  },
  {
    id: 20,
    year: "Apr '24",
    followersGain: 6,
    totalFollowers: 263,
  },
  {
    id: 21,
    year: "May '24",
    followersGain: 8,
    totalFollowers: 271,
  },
  {
    id: 22,
    year: "Jun '24",
    followersGain: 8,
    totalFollowers: 279,
  },
  {
    id: 23,
    year: "Jul '24",
    followersGain: 7,
    totalFollowers: 286,
  },
  {
    id: 24,
    year: "Aug '24",
    followersGain: 1,
    totalFollowers: 287,
  },
  {
    id: 25,
    year: "Sept '24",
    followersGain: 1,
    totalFollowers: 288,
  },
  {
    id: 26,
    year: "Oct '24",
    followersGain: 2,
    totalFollowers: 290,
  },
  {
    id: 27,
    year: "Nov '24",
    followersGain: 0,
    totalFollowers: 289,
  },
  {
    id: 28,
    year: "Dec '24",
    followersGain: 0,
    totalFollowers: 288,
  },
];
