export const FacebookData = [
  {
    id: 1,
    year: "Sept '22",
    followersGain: 0,
    likesGain: 0,
    totalFollowers: 0,
    totalLikes: 0,
  },
  {
    id: 2,
    year: "Oct '22",
    followersGain: 7,
    likesGain: 7,
    totalFollowers: 7,
    totalLikes: 7,
  },
  {
    id: 3,
    year: "Nov '22",
    followersGain: 0,
    likesGain: 0,
    totalFollowers: 7,
    totalLikes: 7,
  },
  {
    id: 4,
    year: "Dec '22",
    followersGain: 2,
    likesGain: 2,
    totalFollowers: 9,
    totalLikes: 9,
  },
  {
    id: 5,
    year: "Jan '23",
    followersGain: 1,
    likesGain: 1,
    totalFollowers: 10,
    totalLikes: 10,
  },
  {
    id: 6,
    year: "Feb '23",
    followersGain: 0,
    likesGain: 0,
    totalFollowers: 10,
    totalLikes: 10,
  },
  {
    id: 7,
    year: "Mar '23",
    followersGain: 13,
    likesGain: 13,
    totalFollowers: 23,
    totalLikes: 23,
  },
  {
    id: 8,
    year: "Apr '23",
    followersGain: 19,
    likesGain: 18,
    totalFollowers: 41,
    totalLikes: 42,
  },
  {
    id: 9,
    year: "May '23",
    followersGain: 7,
    likesGain: 7,
    totalFollowers: 48,
    totalLikes: 49,
  },
  {
    id: 10,
    year: "Jun '23",
    followersGain: 14,
    likesGain: 14,
    totalFollowers: 62,
    totalLikes: 63,
  },
  {
    id: 11,
    year: "Jul '23",
    followersGain: 8,
    likesGain: 8,
    totalFollowers: 70,
    totalLikes: 71,
  },
  {
    id: 12,
    year: "Aug '23",
    followersGain: 6,
    likesGain: 4,
    totalFollowers: 76,
    totalLikes: 75,
  },
  {
    id: 13,
    year: "Sept '23",
    followersGain: 16,
    likesGain: 13,
    totalFollowers: 92,
    totalLikes: 88,
  },
  {
    id: 14,
    year: "Oct '23",
    followersGain: 7,
    likesGain: 7,
    totalFollowers: 99,
    totalLikes: 95,
  },
  {
    id: 15,
    year: "Nov '23",
    followersGain: 4,
    likesGain: 1,
    totalFollowers: 103,
    totalLikes: 96,
  },
  {
    id: 16,
    year: "Dec '23",
    followersGain: 5,
    likesGain: 5,
    totalFollowers: 108,
    totalLikes: 101,
  },
  {
    id: 17,
    year: "Jan '24",
    followersGain: 3,
    totalFollowers: 111,
    likesGain: 3,
    totalLikes: 104,
  },
  {
    id: 18,
    year: "Feb '24",
    followersGain: 5,
    totalFollowers: 116,
    likesGain: 4,
    totalLikes: 108,
  },
  {
    id: 19,
    year: "Mar '24",
    followersGain: 1,
    totalFollowers: 116,
    likesGain: 0,
    totalLikes: 107,
  },
  {
    id: 20,
    year: "Apr '24",
    followersGain: 9,
    totalFollowers: 125,
    likesGain: 1,
    totalLikes: 108,
  },
  {
    id: 21,
    year: "May '24",
    followersGain: 44,
    totalFollowers: 169,
    likesGain: 14,
    totalLikes: 122,
  },
  {
    id: 22,
    year: "Jun '24",
    followersGain: 1,
    totalFollowers: 170,
    likesGain: 0,
    totalLikes: 122,
  },
  {
    id: 23,
    year: "Jul '24",
    followersGain: 1,
    totalFollowers: 171,
    likesGain: 0,
    totalLikes: 122,
  },
  {
    id: 24,
    year: "Aug '24",
    followersGain: 0,
    totalFollowers: 171,
    likesGain: 0,
    totalLikes: 122,
  },
  {
    id: 25,
    year: "Sept '24",
    followersGain: 0,
    totalFollowers: 169,
    likesGain: 0,
    totalLikes: 122,
  },
  {
    id: 26,
    year: "Oct '24",
    followersGain: 1,
    totalFollowers: 170,
    likesGain: 1,
    totalLikes: 123,
  },
  {
    id: 27,
    year: "Nov '24",
    followersGain: 2,
    totalFollowers: 173,
    likesGain: 2,
    totalLikes: 125,
  },
  {
    id: 28,
    year: "Dec '24",
    followersGain: 1,
    totalFollowers: 174,
    likesGain: 0,
    totalLikes: 125,
  },
];
